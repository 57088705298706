export default {
    list: {
        title: "orders",
        name: 'orders',
        class: "min-h-screen flex-1 mt-1 bg-gray-100 px-3 items-center",
        sousTable: true,
        options: {
            defaultPageSize: 5,
            actions: {
                isActive: true,
                method: true,
                link: true,
                name: "Actions",
                buttons: [{
                        text: "new_order",
                        icon: "add",
                        link: '/orders/new',
                        color: 'primary'
                    },
                    {
                        text: "import_orders",
                        icon: "cloud_upload",
                        link: '/orders/import',
                        color: 'primary'
                    },
                    {
                        text: "change_status",
                        icon: "update",
                        action:"changestatus",
                        color: 'primary',
                        class:''
                    },
                    {
                        text: "Delete orders",
                        icon: "delete_forever",
                        action:"deleteorders",
                        color: 'primary',
                        class:''
                    },
                ],
            }
        },
        columns: [
            {
                type:"checkbox",
                elements: [{
                   items: [{
                       class: "",
                       type: "checkbox",
                   }, ],
               }, ]
            },
            {
                name: "ID",
                class: "",
                elements: [{
                    items: [{
                        class: "",
                        name: "id",
                        type: "text",
                    }],
                }, ]
            },
            {
                name: "source",
                class: "",
                elements: [{
                    items: [{
                        class: "",
                        name: "source",
                        type: "text",
                    }],
                }, ]
            },
            {
                name: "seller",
                elements: [{
                    items: [{
                        class: "ouka",
                        name: "seller",
                        elements: [{
                            items: [{
                                name: "fullName",
                                class: "font-light text-gray-700",
                            }]
                        }]

                    }, ],
                }, ]
            },
            {
                name: "customer",
                elements: [{
                    items: [{
                        class: "ouka",
                        name: "customer",
                        elements: [{
                            items: [{
                                    name: "fullName",
                                },
                                {
                                    name: "phone",
                                    class: "font-light text-gray-700",
                                }
                            ]
                        }]

                    }, ],
                }, ]
            },
            {
                name: "details",
                model: "order",
                title: 'products'
            },
            // {
            //     name: "Shipping Address",
            //     elements: [{
            //         items: [{
            //             class: "ouka",
            //             name: "customer",
            //             elements: [{
            //                 items: [{
            //                     name: "shippingAddress",
            //                 }]
            //             }]

            //         }, ],
            //     }, ]
            // },
            {
                name: "total_price",
                class: "",
                elements: [{
                    items: [{
                        class: "",
                        name: "totalPrice",
                        type: "text",
                    }],
                }, ]
            },
            {
                name: "order_date",
                class: "",
                elements: [{
                    items: [{
                        class: "",
                        date: true,
                        name: "date",
                        type: "text",
                    }],
                }, ]
            },
            {
                name: "status",
                class: "text-center",
                elements: [{
                    items: [{
                        class: "border capitalize rounded-full text-gray-600 h-8 text-xs px-8 bg-white hover:border-gray-500 focus:outline-none appearance-none",
                        name: "status",
                        data: true,
                        champs: {
                            name: "name",
                            color: "color",
                        },
                    }],
                }, ]
            },
        ],
        columnsDialog: {
            details: 'details',
            elements: [{
                    class: "",
                    name: "image",
                    referent: "product",
                    items: [{
                        name: "picture",
                    }, ]
                },
                {
                    class: "",
                    name: "sous warehouse",
                    items: [{
                        name: "warehouseParentName",
                    }, ]
                },
                {
                    class: "",
                    name: "name",
                    referent: "product",
                    items: [{
                        name: "name",
                    }, ]
                },
                // {
                //     class: "",
                //     name: "code",
                //     referent: "product",
                //     items: [{
                //         name: "code",
                //     }, ]
                // },
                {
                    class: "",
                    name: "unit_price",
                    items: [{
                        name: "unitPrice",
                    }, ]
                },
                {
                    class: "",
                    name: "quantity",
                    items: [{
                        name: "quantity",
                    }, ]
                },
            ]
        },
        actions: {
            name: "actions",
            width: "10px",
            class: "justify-end",
            elements: [
                {
                    icone: "line_weight",
                    color: "success",
                    role: true,
                    key: 'double',
                    tooltip: "Double",
                },
                {
                    icone: "remove_red_eye",
                    color: "success",
                    key: 'read',
                    id: "",
                    role: true,
                    link: '/orders/detail',
                    tooltip: "Update",
                },
                {
                    icone: "edit",
                    color: "success",
                    role: true,
                    key: 'update',
                    link: '/orders/edit',
                    tooltip: "Update",
                },
                {
                    icone: "delete",
                    color: "danger",
                    role: true,
                    key: 'delete',
                    tooltip: "Delete",
                }
            ],
        },
        filters: {
            name: 'orders',
            elements: {
                search: {
                    options: {
                        icon: "search",
                        label: "search",
                        class: "",
                        hint: "search_order",
                    },
                    value: null,
                    type: "search",
                    validate: {
                        trim: true,
                        required: false,
                        minlength: 0,
                    }
                },
                champs: [
                    {
                        label: "status",
                        key: "status",
                        name: "name",
                        value: null,
                        values: [],
                        class: 'py-2 text-xs  capitalize px-2 outline-none block h-full w-full bg-white',
                        type: "select",
                    },
                    {
                        label: "pending status",
                        key: "status_pending",
                        name: "name",
                        value: null,
                        values: [],
                        class: 'py-2 text-xs  capitalize px-2 outline-none block h-full w-full bg-white',
                        type: "select",
                    },
                    {
                        label: "Nombre des appels",
                        key: "range_numbers",
                        name: "",
                        value: {min:0,max:0},
                        values: [],
                        class: 'py-2 text-xs  capitalize px-2 outline-none block h-full w-full bg-white',
                        type: "range_numbers",
                    },
                    {
                        label: "Date Remind",
                        key: "date_remind",
                        name: "date_remind",
                        value: null,
                        values: [],
                        class: 'py-2 text-xs capitalize px-2 outline-none block h-full w-full bg-white',
                        type: "date_range",
                    },
                    {
                        label: "seller",
                        key: "seller",
                        name: "fullName",
                        value: null,
                        values: [],
                        class: 'py-2 text-xs  capitalize px-2 outline-none block h-full w-full bg-white',
                        type: "select",
                    },
                    {
                        label: "product",
                        key: "product",
                        name: "name",
                        value: null,
                        values: [],
                        class: 'py-2 text-xs  capitalize px-2 outline-none block h-full w-full bg-white',
                        type: "select",
                    },
                    {
                        label: "date",
                        key: "Range_date",
                        value: null,
                        placeholder: "Date Range ...",
                        type: "date_range",
                        class: "py-2 text-xs px-2 outline-none block h-full w-full"
                    },
                ],
            },
        },
    },
}